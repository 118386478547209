<template>
  <div></div>
</template>

<script>
export default {
  name: 'redirect',
  created () {
    const mainUrl = location.href.replace('/cn/redirect', '')
    console.log(mainUrl)
    if (window.navigator.language.slice(0, 2) === 'zh') {
      const newURl = mainUrl + '/cn/BTCInfo'
      window.location.href = newURl
    } else {
      const newURl = mainUrl + '/BTCInfo'
      window.location.href = newURl
    }
  }
}
</script>
